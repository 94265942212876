<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
  .width-body {
    max-width: 980px;
  }

  .spinner {
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 999999;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .spinner-item {
    margin-top: 45vh;
  }
  
  .th-action {
    width: 200px;
  }
</style>