import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import Icon from 'vue-icon'
import VueTheMask from 'vue-the-mask'
import VeeValidate from 'vee-validate'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'font-awesome/css/font-awesome.css';

import router from './router/index'
import validatorMessage from './config/validatorMessage'

import VueQuillEditor from "vue-quill-editor";
import 'quill/dist/quill.snow.css'
import money from 'v-money'

Vue.config.productionTip = false
	
Vue.use(VueQuillEditor)
Vue.use(VueTheMask)
Vue.use(BootstrapVue)
Vue.use(Icon, 'vue-icon')
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: ' #',
  precision: 2,
  masked: false
})
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
  locale: 'pt_BR',
  dictionary: {
    pt_BR:{
      messages: validatorMessage
    }
  }
});

Vue.prototype.$http = axios

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
