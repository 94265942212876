import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Form = () => import('../page/Form/index')
const Login = () => import('../page/Login/index')
const Admin = () => import('../page/Admin/index')
const ConfigurationList = () => import('../page/ConfigurationList/index')
const ConfigurationForm = () => import('../page/ConfigurationForm/index')
const BrandList = () => import('../page/BrandList/index')
const BrandForm = () => import('../page/BrandForm/index')
const BrandPriceList = () => import('../page/BrandPriceList/index')
const BrandPriceModel = () => import('../page/BrandPriceModel/index')
const BrandNumberModelList = () => import('../page/BrandNumberModelList/index')

const routes = [
    { name: 'Form', path: '/', component: Form },
    { name: 'Login', path: '/login', component: Login },
    { name: 'Admin', path: '/admin', component: Admin, meta: { private: true }  },
    { name: 'ConfigurationList', path: '/admin/configuration', component: ConfigurationList, meta: { private: true } },
    { name: 'ConfigurationEdit', path: '/admin/configuration/:id', component: ConfigurationForm, meta: { private: true } },
    { name: 'BrandNumberModelList', path: '/admin/brand/:id/model', component: BrandNumberModelList, meta: { private: true } },
    { name: 'BrandPriceList', path: '/admin/brand/:id/model/:numberModel', component: BrandPriceList, meta: { private: true } },
    { name: 'BrandPriceModel', path: '/admin/brand/price/:id', component: BrandPriceModel, meta: { private: true } },
    { name: 'BrandList', path: '/admin/brand', component: BrandList, meta: { private: true } },
    { name: 'BrandForm', path: '/admin/brand/:id', component: BrandForm, meta: { private: true } },
    { name: 'Default', path: '/*', component: Form }
]

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((routeTo, routeFrom, next) => {
  if (routeTo.meta.private) {
    if (!localStorage.getItem('auth') || !localStorage.getItem('sgbAuth')) {
      return next({
        path: '/login'
      })
    }
  }
  
  next()
})
  
export default router